import PhotoCollage from "../components/PhotoCollage"

function Services(){
    return(
        <section className="w-full h-fit flex flex-col">
            <h2 className="mx-auto my-5 text-xl md:text-2xl lg:text-4xl font-semibold text-red" > What we offer</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-center px-2">
            {
                services.map( service => (
                    <div key={service.id} className="rounded-2xl bg-red bg-opacity-30 w-full h-28 flex justify-center items-center">
                        <p className="text-blue font-semibold text-center">{service.name}</p>
                    </div>
                ))
            }
            </div>
            <PhotoCollage />
        </section>
    )

}

export default Services

const services = [
    {
        id: 0,
        name: "efflurage massage"
    },
    {
        id: 1,
        name: "sweedish massage"
    },
    {
        id: 2,
        name: "body - to - body massage"
    },
    {
        id: 3,
        name: "petrissage"
    },
    {
        id: 4,
        name: "tapotement"
    },
    {
        id: 5,
        name: "full body massage"
    },
]