import Profile1 from "./photo_5897571988623442530_y.jpg";
import Profile2 from "./photo_5897571988623442531_y.jpg";
import Profile3 from "./photo_5897571988623442532_y.jpg";
import Profile4 from "./photo_5897571988623442533_y.jpg";
import Profile5 from "./photo_5897571988623442534_y.jpg";
import Profile6 from "./photo_5897571988623442535_y.jpg";
import Profile7 from "./photo_5897571988623442536_y.jpg";
import Profile8 from "./photo_5897571988623442537_y.jpg";
import Profile9 from "./photo_5897571988623442538_y.jpg";
import Profile10 from "./photo_5897571988623442539_y.jpg";
import Profile11 from "./photo_5897571988623442540_y.jpg";
import Profile12 from "./photo_5897571988623442541_y.jpg";

export const collageImages = [
    Profile1,
    Profile2,
    Profile3,
    Profile4,
    Profile5,
    Profile6,
    Profile7,
    Profile8,
    Profile9,
    Profile10,
    Profile11,
    Profile12
]