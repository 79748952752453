import Contact from "../homesections/Contact";
import Hero from "../homesections/Hero";
import Services from "../homesections/Services";
import SocialLinks from "../homesections/SocialLinks";

function Home(){
    return(
        <div className="h-full">
           <Hero />
           <Services />
           <Contact />
           <SocialLinks/>
        </div>
    )
}

export default Home;