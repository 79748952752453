function Footer(){
    const year = new Date().getFullYear()

    return(
        <footer className="bg-red bg-opacity-60 rounded-t-2xl mt-5 text-blue flex items-center justify-center h-20 text-xs font-semibold">
            <span> copyright &copy; {year}. All rights reserved</span>
        </footer>
    )
}

export default Footer;