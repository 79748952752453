import { collageImages } from "../images/post";

function PhotoCollage(){
    return(
        <div className="flex flex-wrap justify-center gap-2 px-4 py-5 my-12">
            {
                collageImages.map( (photo, index) => (
                    <img loading="lazy" key={index} src={photo} alt="profiles" className="rounded-2xl h-56 md:h-64 lg:h-80 hover:scale-150  hover:border-2 hover:border-red transition ease-in-out delay-150 duration-300"/>
                ))
            }
        </div>
    )
}

export default PhotoCollage;