import HeroImage from "../images/hero.jpg"

function Hero(){
    return(
        <section className="relative w-full h-[calc(100vh_-40vh)] ">
            <img loading="lazy" src={HeroImage} alt="hero cover" className="object-cover h-full w-full"/>
            <div className="bg-blue bg-opacity-60 absolute inset-0 w-full h-full">
                
                <div className=" w-3/5 lg:w-1/2 flex flex-col justify-center  gap-5 h-full px-4 md:px-7">
                    <h2 className="font-semibold text-2xl lg:text-4xl text-red md:text-4xl "> Your Body Deserves a Vacay</h2>
                    <p className="font-light text-white md:text-xl">
                    Unwind, rejuvenate, repeat. Experience pure bliss with our personalized massage treatments. Let us melt away your stress and restore your balance. We do both incalls and outcalls.
                    </p>
                    <a href="https://wa.me/254705237742" target="_blank" rel="noreferrer" className="bg-red self-center rounded-ee-full w-[200px] md:w-[250px] rounded-ss-full py-2 flex justify-center ">
                        <span className="font-semibold text-white md:text-xl">book with us now</span>
                    </a>
                </div>
                
            </div>
        </section>
    )
}

export default Hero;