import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";

function App() {
  return (
    <div className="w-full min-h-screen  bg-white">
      <Header/>
      <Home />
      <Footer/>
    </div>
  );
}

export default App;
