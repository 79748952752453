import WhatsappIcon from "../images/whatsapp.png"
function SocialLinks(){
    return (
        <div className="px-2 py-4 rounded-2xl z-20 fixed w-fit  bottom-7 right-4">
           <a aria-label="Chat on WhatsApp" target="_blank" rel="noreferrer" href="https://wa.me/254705237742" className="h-8 w-8 ">
                <img alt="Chat on Whatsapp" src={WhatsappIcon}  className="object-contain hover:scale-125 " height={60} width={60}/>
           </a>
        </div>
    )

}

export default SocialLinks