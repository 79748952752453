
import Mail from "../images/mail.svg";
import Phone from "../images/phone.svg";
import Map from "../images/map.svg";
import Truck from "../images/truck.svg";

function Contact(){
    return(
        <section className="w-full flex flex-col" >
            <h2 className="mx-auto my-5 text-xl md:text-2xl lg:text-4xl font-semibold text-red " > How to reach us</h2>
            <div className="flex flex-col md:flex-row gap-4 md:justify-evenly">
                <div className="flex flex-col gap-2 px-3 items-center md:text-xl">
                    <p>We are open 24/7 </p>
                    <p className="flex gap-2 font-semibold">
                       <img src={Map} alt="location" className="h-6 w-8" /> 
                       <span>Spa located at Kilimani</span>
                    </p>
                    <p className="flex gap-2 font-semibold">
                       <img src={Truck} alt="location" className="h-6 w-8" /> 
                       <span>Also mobile. Bringing services closer to your doorstep</span>
                    </p>
                    <p className="flex gap-2 font-semibold">
                       <img src={Phone} alt="phone" className="h-6 w-8" /> 
                       <span>+254 705 237 742</span>
                    </p>
                    <p className="flex gap-2 font-semibold">
                       <img src={Mail} alt="email" className="h-6 w-8" /> 
                       <span>info@cumknockmeup.com</span>
                    </p>
                </div>
               
            </div>
        </section>
    )
}

export default Contact