

function Header(){
    return (
        <div className="w-full z-10 fixed top-0 bg-blue bg-opacity-30" >
            <h1 className="text-3xl md:text-5xl px-4 py-2 text-opacity-80 font-['Matemaise'] font-bold text-red ">Cum knock me up</h1>
        </div>
    )
}

export default Header;